<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sinPaddingDer" *ngIf="propiedadRelacionada && propiedadRelacionada.length > 0">
    <div class="contenedorPropiedadesSimilares ">
        <p class="tituloPropiedadesSimilares">Propiedades similares</p>
        <hr />
        <div class="contenedorDetallePropiedadesSimilares " *ngFor="let proRelacionada of propiedadRelacionada">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="contenedorImgPropiedadesSimilares">
                        <img [src]="proRelacionada.imagen" class="img-fluid" />
                        <div class="contenedorOferta" *ngIf="proRelacionada.precioEspecial">
                            <p class="oferta" >
                                Descuento
                            </p>
                        </div>
                        <p class="textoPrecioPropiedadesSimilares">{{proRelacionada.monedasSimbolo}}{{proRelacionada.precio |number | removeComma}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <p class="tituloPropiedadPropiedadesSimilares"></p>
                    <p class="textoPropiedadPropiedadesSimilares">{{proRelacionada.zonasRutaInversa}}
                        {{proRelacionada.direccion}}</p>
                    <a class="lnkPropiedasSimilares" routerLink="/detalle/{{proRelacionada.codigo}}">Más detalles ></a>
                </div>
            </div>
            <hr />
        </div>
        
    </div>
</div>
