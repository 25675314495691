import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mucap-boletin-externo-mdl',
  templateUrl: './mucap-boletin-externo-mdl.component.html',
  styleUrls: ['./mucap-boletin-externo-mdl.component.css']
})
export class MucapBoletinExternoMdlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
