import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BuscadorService {
  private _url='https://mucap-propiedades-api.azurewebsites.net/api/';
  constructor(private http:HttpClient) { }
  buscar(codigoZonasNivel0:string | null, codigoZonasNivel1:string | null, areaMinima:number | null, areaMaxima:number | null, dormitorios:number | null,
    parqueos:number | null, precioMinimo:number | null, precioMaximo:number | null, tipo:string,activo:boolean, criterio:string,pagina:number,cantidadRegistrosPagina:number
    ): Observable<any>{
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigoZonasNivel0": codigoZonasNivel0,
      "codigoZonasNivel1": codigoZonasNivel1,
      "areaMinima": areaMinima,
      "areaMaxima": areaMaxima,
      "dormitorios": dormitorios,
      "parqueos": parqueos,
      "precioMinimo": precioMinimo,
      "precioMaximo": precioMaximo,
      "tipo": tipo,
      "activo":activo,
      "criterio":criterio,
      "pagina": pagina,
      "cantidadRegistrosPagina": cantidadRegistrosPagina
    })
    return this.http.post<any>(this._url + 'propiedades/buscar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  listarUltimas(): Observable<any>{
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
     
    })
    return this.http.post<any>(this._url + 'propiedades/listarUltimas', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  listarPropiedad(codigo:string): Observable<any>{
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
     
    })
    return this.http.post<any>(this._url + 'propiedades/listar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  errorHandler(error:HttpErrorResponse){
    return throwError(error);
  }
}
