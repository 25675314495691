<div class="container-fluid fondoSuscripcion">
    <div class="row contenedorSuscripcion">
        <div class="col-12 col-sm-12 col-md-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <h3>¡Ponte en contacto con nosotros!</h3>
            <hr class="lineaSuscripcion" />
            <p class="textoSuscripcion">Enterate de las nuevas propiedades a la venta, promociones, descuentos y más
                beneficios.</p>
        </div>
        <div class="col-12 col-sm-12 col-md-1"></div>
        <div class="col-12 col-sm-12 col-md-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
            <form #formSolicitud="ngForm">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <input class="form-control ctrlBuscador espaciadoArriba" type="text" placeholder="Nombre"
                            [(ngModel)]="correo.nombre" name="nombre" #nombre="ngModel" required>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <input class="form-control ctrlBuscador espaciadoArriba" type="text" placeholder="Teléfono"
                            [(ngModel)]="correo.telefono" name="telefono" #telefono="ngModel" required />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <input class="form-control ctrlBuscador espaciadoArriba" type="text"
                            placeholder="Correo electrónico" [(ngModel)]="correo.correoElectronico"
                            name="correoElectronico" #correoElectronico="ngModel" required />
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                        <textarea id="mensaje" name="mensaje" class="form-control ctrlBuscador espaciadoArriba"
                            placeholder="Mensaje" [(ngModel)]="correo.mensaje" rows="4" required>
                        </textarea>
                    </div>
                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-1">
                        <button type="button" class="btn btn-danger btnEnviar" (click)="enviarCorreo()"
                            [disabled]="formSolicitud.invalid">Enviar</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 col-sm-12 col-md-1"></div>
    </div>
</div>