import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mucap-contactos-mdl',
  templateUrl: './mucap-contactos-mdl.component.html',
  styleUrls: ['./mucap-contactos-mdl.component.css']
})
export class MucapContactosMdlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
