<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center">
            <h1>Contactos</h1>
            <h2>Conocé a nuestro equipo de líderes</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2"></div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-3 col-xl-2">
            <div class="contenedorColaborador">
                <div class="row">
                    <div class="col text-center"><img src="../../assets/imgs/Img-Contactos-ManuelSalas-200x199.png"
                            class="img-fluid altoImagenContacto" />
                    </div>
                </div>
                <div class="row">
                    <div class="col fondoColaborador"><span class="textoColaborador">Manuel Salas Sanabria</span></div>
                </div>
                <div class="row">
                    <div class="col">
                        <p><a href="tel:25507938" class="lnkContacto"><img src="../../assets/imgs/icono-Telefono.svg"
                                    class="imgContacto" /> 2550-7938</a></p>
                        <p><a href="mailto:msalas@mucap.fi.cr" class="lnkContacto"><img
                                    src="../../assets/imgs/icono-Correo.svg" class="imgContacto" />
                                msalas@mucap.fi.cr</a></p>
                        <p><a href="https://api.whatsapp.com/send?phone=50670138584" class="lnkContacto"><img
                                    src="../../assets/imgs/icono-Whatsapp.svg" class="imgContacto" /> 7013-8584</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1 espacioContactoIpad"></div>
        <div class="col-md-1 espacioContactoIpad"></div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-3 col-xl-2">
            <div class="contenedorColaborador">
                <div class="row">
                    <div class="col text-center"><img src="../../assets/imgs/Img-Contactos-JuanCarlosH-200x199.png"
                            class="img-fluid altoImagenContacto" />
                    </div>
                </div>
                <div class="row">
                    <div class="col fondoColaborador"><span class="textoColaborador">Juan Carlos Hernández Arce</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p><a href="tel:25508453" class="lnkContacto"><img src="../../assets/imgs/icono-Telefono.svg"
                                    class="imgContacto" /> 2550-8453</a></p>
                        <p><a href="mailto:jhernandeza@mucap.fi.cr" class="lnkContacto"><img
                                    src="../../assets/imgs/icono-Correo.svg" class="imgContacto" />
                                jhernandeza@mucap.fi.cr</a></p>
                        <p><a href="https://api.whatsapp.com/send?phone=50686344680" class="lnkContacto"><img
                                    src="../../assets/imgs/icono-Whatsapp.svg" class="imgContacto" /> 8634-4680</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 espacioContacto"></div>
        <div class="col-lg-1 espacioContacto"></div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-3 col-xl-2">
            <div class="contenedorColaborador">
                <div class="row">
                    <div class="col text-center"><img src="../../assets/imgs/Img-Contactos-GreivinRamirez-200x199.png"
                            class="img-fluid altoImagenContacto" />
                    </div>
                </div>
                <div class="row">
                    <div class="col fondoColaborador"><span class="textoColaborador">Greivin Ramírez Brenes</span></div>
                </div>
                <div class="row">
                    <div class="col">
                        <p><a href="tel:25508455" class="lnkContacto"><img src="../../assets/imgs/icono-Telefono.svg"
                                    class="imgContacto" /> 2550-8455</a></p>
                        <p><a href="mailto:gramirezb@mucap.fi.cr" class="lnkContacto"><img
                                    src="../../assets/imgs/icono-Correo.svg" class="imgContacto" />
                                gramirezb@mucap.fi.cr</a></p>
                        <p><a href="https://api.whatsapp.com/send?phone=50683281225" class="lnkContacto"><img
                                    src="../../assets/imgs/icono-Whatsapp.svg" class="imgContacto" /> 8328-1225</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1 espacioContactoIpad"></div>
        <div class="col-md-1 espacioContactoIpad"></div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-3 col-xl-3">
            <div class="contenedorColaborador">
                <div class="row">
                    <div class="col text-center"><img src="../../assets/imgs/Img-Contactos-Mucap-200x199.png"
                            class="img-fluid altoImagenContacto" />
                    </div>
                </div>
                <div class="row">
                    <div class="col fondoColaborador"><span class="textoColaborador">Departamento Propiedades a la
                            Venta</span></div>
                </div>
                <div class="row">
                    <div class="col">
                        <p><a href="tel:25508454" class="lnkContacto"><img src="../../assets/imgs/icono-Telefono.svg"
                                    class="imgContacto" /> 2550-8450</a>, <a href="tel:25508454"
                                class="lnkContacto">2550-8451</a>, <a href="tel:25508454"
                                class="lnkContacto">2550-8679</a></p>
                        <p><a href="mailto:ventadebienes@mucap.fi.cr" class="lnkContacto"><img
                                    src="../../assets/imgs/icono-Correo.svg" class="imgContacto" />
                                ventadebienes@mucap.fi.cr</a></p>
                        <p><a href="#" class="lnkContacto"><img src="../../assets/imgs/icono-Hora.svg"
                                    class="imgHorario" /> Lunes a viernes de 8:00 am a 5:30 pm</a></p>
                        <p><a href="#" class="lnkContacto"><img src="../../assets/imgs/icono-Whatsapp.svg"
                                    class="imgContacto" /> Lunes a viernes de 8:00 am a 5:30 pm</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>