import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MucapDetallePropiedadComponent } from './componentes/mucap-detalle-propiedad/mucap-detalle-propiedad.component';
import { MucapHomeComponent } from './componentes/mucap-home/mucap-home.component';
const routes: Routes = [
  {
    path: '',
    component: MucapHomeComponent
  },
  {
    path: 'detalle/:codigo',
    component: MucapDetallePropiedadComponent,
    
   
  }
 
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
