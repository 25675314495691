import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { ImagenesService } from 'src/app/servicios/imagenes.service';
import { ArchivosService } from 'src/app/servicios/archivos.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BuscadorService } from 'src/app/servicios/buscador.service';

import { Router } from '@angular/router';


@Component({
  selector: 'app-mucap-informacion-propiedad',
  templateUrl: './mucap-informacion-propiedad.component.html',
  styleUrls: ['./mucap-informacion-propiedad.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: false, showIndicators: true } }
 ],
})
export class MucapInformacionPropiedadComponent implements OnInit {
  noWrapSlides = false;
  showIndicator = true;
  galleryOptions : NgxGalleryOptions [];
  galleryImages : NgxGalleryImage [];
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
propiedad:any;
imagenes:any;
prueba:{small:any,medium:any,big:any};
archivos:any;
propiedadRelacionada:any;
datos:any;
tamanios:any;
images:any=[]
  constructor(private imagenesSvg:ImagenesService,private route: ActivatedRoute, private archivosSvg:ArchivosService,
     private buscadorSvg:BuscadorService,private router: Router  ) {
       this.route.paramMap.subscribe(paramMap => {
      const id = paramMap.get('codigo');    // get param from dictonary
      this.listar();   
      this.listarImagenes();
    this.listarArchivos();                 // load your data
  }); }

  ngOnInit(): void {
 
    
    this.galleryOptions = [
      {
        width: '600px',
        height: '600px',
        thumbnailsColumns: 4,
        imageAutoPlay: true,
        imageAutoPlayPauseOnHover: true,
        imageArrows: false,
        arrowPrevIcon: 'fa fa-arrow-left',
        arrowNextIcon: 'fa fa-arrow-right',
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 1024
      {
        breakpoint: 1024,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsArrows: true,
        fullWidth: true
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '500px',
        imagePercent: 80,
        thumbnailsColumns: 4,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsArrows: true,
        fullWidth: true
      },
      // max-width 400
      {
        breakpoint: 400,
        width: '100%',
        height: '100px',
        preview: false,
        thumbnailsColumns: 2,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsArrows: true,
        fullWidth: true
      },
      // max-width 320
      {
        breakpoint: 320,
        width: '80%',
        height: '100px',
        preview: false,
        imagePercent: 80,
        thumbnailsColumns: 2,
        thumbnailsPercent: 20,
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        thumbnailsArrows: false,
        fullWidth: true,
        imageArrows: true
      }
    ];

    
    console.log(this.galleryImages);
  }
  listarImagenes(){
    this._status = 'b'
    this.imagenesSvg.listarImagenes(this.route.snapshot.params['codigo'])
     .subscribe(data =>{
       this.imagenes=[];
       this.images=[];
       this.imagenes=data;
       for (let i = 0; i < this.imagenes.length; i++) {
        this.tamanios={small:this.imagenes[i].imagen,medium:this.imagenes[i].imagen,big:this.imagenes[i].imagen}
        this.images.push(this.tamanios);
        
       }
      this.galleryImages=this.images;
       this._status = 'c'
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  listarArchivos(){
    this._status = 'b'
    this.archivosSvg.listarArchivos(this.route.snapshot.params['codigo'])
     .subscribe(data =>{
       this.archivos=data;
      
       this._status = 'c'
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  listar(){
    this._status = 'b'
    this.buscadorSvg.listarPropiedad(this.route.snapshot.params['codigo'])
     .subscribe(data =>{
       this.propiedad=data;
       this.datos=Object.assign({}, this.propiedad[0]);
       this._status = 'c'
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  scrollTop(event: any) {
    window.scroll(0,0);
  }
  
}
