
<!-- <router-outlet></router-outlet>
<app-header></app-header>
 <app-buscador></app-buscador> 
<app-detalle></app-detalle>
<app-contactos></app-contactos>
<app-boletin></app-boletin>
<app-buscador></app-buscador> 
<app-footer></app-footer> -->

<!--Componentes de karen-->

<router-outlet></router-outlet>

