import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SalidaDatosService } from 'src/app/servicios/salida-datos.service';
import { BuscadorService } from 'src/app/servicios/buscador.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-mucap-buscador',
  templateUrl: './mucap-buscador.component.html',
  styleUrls: ['./mucap-buscador.component.css']
})
export class MucapBuscadorComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  registros:any;
  pagina!:number;
  cantidadRegistrosPagina!:number;
  conteo!:number;
  paginas: {indice:number, grupo:number}[]=[];
  grupos!:number;
  grupo!:number;
  cantidadPaginasGrupo!:number;
  filtros = {
  codigoZonasNivel0: null,
  codigoZonasNivel1: null,
  areaMinima: null,
  areaMaxima: null,
  dormitorios: null,
  parqueos: null,
  precioMinimo: null,
  precioMaximo: null,
  tipo: null,
  activo:true,
  criterio:'%%'
 
  }
  titulo:any;
  propiedadesRecientes:boolean=true;
  constructor(private salidaSvg:SalidaDatosService, private buscadorSvg:BuscadorService) {    
    this.salidaSvg.disparador.subscribe(dt=>{
    this.filtros=dt;
    this.buscar();
  })}

  ngOnInit(): void {
    if(this.propiedadesRecientes){
    this.listarUltimas();
    }
 
  }
  listarUltimas(): void {
    this.titulo='PROPIEDADES RECIENTES'
    this.buscadorSvg.listarUltimas()
    .subscribe(data =>{
      console.log(data);
      this.registros=data
      
      this._status = 'c'

    },
      error => {
       this.errorHttp=error;
       alert(error.statusText);
     });
  }
  obtenerRegistros(): void{
    
    this.titulo='RESULTADOS DE BÚSQUEDA';
    this._status = 'b'
    this.buscadorSvg.buscar(this.filtros.codigoZonasNivel0,this.filtros.codigoZonasNivel1,this.filtros.areaMinima,this.filtros.areaMaxima,this.filtros.dormitorios,
      this.filtros.parqueos,this.filtros.precioMinimo,this.filtros.precioMaximo, this.filtros.tipo,this.filtros.activo,this.filtros.criterio, this.pagina, this.cantidadRegistrosPagina)
     .subscribe(data =>{
      this._status = 'c'
       this.registros=data
       this.conteo = 0;
       if (this.registros.length > 0) { this.conteo = parseInt(this.registros[0].conteo) };
       this.calcularPaginas();
      
    
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  //paginacion
 calcularPaginas() {
  var tmp = Math.ceil(this.conteo / this.cantidadRegistrosPagina);
  var tmpArr = new Array();
  tmp = (tmp > 0 ? tmp : 1);
  for (var i = 1, ii = tmp; i <= ii; i++) {
      tmpArr.push({
          indice: i,
          grupo: Math.ceil((i / this.cantidadPaginasGrupo)),
          clase: (i == this.pagina ? 'active' : '')
      });
  }
  this.paginas = tmpArr;
  this.grupos = Object.assign({},this.paginas[this.paginas.length - 1].grupo);
};
 buscar() {
  this.pagina = 1;
  this.cantidadRegistrosPagina = 6;
  this.cantidadPaginasGrupo=5;
  this.conteo = 0;
  this.paginas = [{ indice: 1, grupo: 1 }];
  this.grupos = 1;
  this.cambiarPagina(1);
}
cambiarPagina(pagina:number){
  this.pagina = pagina;
  this.grupo = Math.ceil((this.pagina / this.cantidadPaginasGrupo));
 this.obtenerRegistros(); 
 window.scrollTo( 0, 900 );
}

retroceder() {
  var tmp = (this.grupo - 1) * this.cantidadPaginasGrupo;
  this.cambiarPagina(tmp);
};
avanzar() {
  var tmp = (this.grupo * this.cantidadPaginasGrupo) + 1;
  this.cambiarPagina(tmp);
};
scrollToBottom(): void {
  try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  } catch(err) { }                 
}
}
