<div class="container-fluid fondoSuscripcion">
    <div class="row contenedorSuscripcion">
        <div class="col-12 col-sm-12 col-md-1"></div>
        <div class="col-12 col-sm-12 col-md-10 col-lg-4 col-xl-5">
            <h3>¡Suscribete a nuestro boletín!</h3>
            <hr class="lineaSuscripcion" />
            <p class="textoSuscripcion">Enterate de las nuevas propiedades a la venta, promociones, descuentos y más
                beneficios.</p>
        </div>
        <div class="col-12 col-sm-12 col-md-1 col-lg-1"></div>
        <div class="col espacioContactoIpad"></div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-3">
            <input class="form-control ctrlBuscador" type="text" placeholder="Nombre">
            <input class="form-control ctrlBuscador espaciadoArriba" type="text" placeholder="Teléfono" />
            <input class="form-control ctrlBuscador espaciadoArriba" type="text" placeholder="Correo electrónico" />
        </div>
        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-1">
            <button type="button" class="btn btn-danger btnEnviar">Enviar</button>
        </div>
        <div class="col-12 col-sm-12 col-md-1"></div>
    </div>
</div>