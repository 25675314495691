
<app-mucap-header></app-mucap-header>
<div class="container-fluid fondoGris">
    <div class="row">
        <div class="col mostrarDesk"></div>
        <div class="col-col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
            <h4>Buscar propiedades</h4>
       
        </div>
        <div class="col-col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
          
        </div>
        <div class="col-col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
           
        </div>
        <div class="col-2 mostrarDesk"></div>
    </div>
    <div class="row">
        <div class="col mostrarDesk"></div>
        
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.tipo" name="propiedad"
                #propiedad="ngModel" (click)="onChange(filtros.tipo)">
                <option value="null">Propiedad</option>
                <option value="a">Vivienda</option>
                <option value="b">Lote</option>
                <option value="c">Comercial</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.codigoZonasNivel0" name="provincia"
                #provincia="ngModel" (change)="cargarCantones(filtros.codigoZonasNivel0)">
                <option value="null">Provincia</option>
                <option *ngFor="let provincia of provincias" [ngValue]="provincia.codigo">{{provincia.descripcion}}</option>
            </select>
        </div>

        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">

            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.codigoZonasNivel1" name="canton" #canton="ngModel" (click)="onChangeCanton(filtros.codigoZonasNivel1)"  >
                <option value="null">Cantón</option>
                <option *ngFor="let canton of cantones" [ngValue]="canton.codigo">{{canton.descripcion}}</option>

            </select>
        </div>
        <div class="col-2 mostrarDesk"></div>
    </div>
    <div class="row row-eq-height">
        <div class="col-12 col-sm-12  col-lg-1 col-xl-1 mostrarDesk"></div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
            <div class="row">
                <div class="col-12">
                    <p class="textoFiltro">Rango de precios</p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 form-group">
                        <div class="input-icon">
                            <input class="form-control ctrlBuscador" mask="separator.3" thousandSeparator="." separatorLimit="999999999" [allowNegativeNumbers]="false" placeholder="Currency"  [(ngModel)]="filtros.precioMinimo">
                          <i style="color:#999999">₡ </i>
                        </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <div class="input-icon">
                        <input class="form-control ctrlBuscador" mask="separator.3" thousandSeparator="." separatorLimit="999999999" [allowNegativeNumbers]="false" placeholder="Max"  [(ngModel)]="filtros.precioMaximo">
                      <i style="color:#999999">₡ </i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3" *ngIf="filtros.tipo=='b' || filtros.tipo=='c'">
            <div class="row">
                <div class="col-12">
                    <p class="textoFiltro">Rango de área m2</p>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <div class="input-icon input-icon-right">
                        <input class="form-control ctrlBuscador" mask="separator.1" thousandSeparator="." separatorLimit="99999" [allowNegativeNumbers]="false" placeholder="Min"  [(ngModel)]="filtros.areaMinima">
                      <i style="color:#999999">m2 </i>
                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <div class="input-icon input-icon-right ">
                        <input class="form-control ctrlBuscador" mask="separator.1" thousandSeparator="." separatorLimit="99999" [allowNegativeNumbers]="false" placeholder="Max"  [(ngModel)]="filtros.areaMaxima">
                      <i style="color:#999999">m2 </i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControlesAbajo"
            *ngIf="filtros.tipo=='a'">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.dormitorios" name="dormitorios"
                #dormitorios="ngModel">
                <option value="null">Dormitorios</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControlesAbajo"
            *ngIf="filtros.tipo=='a'">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.parqueos" name="parqueos" #parqueos="ngModel">
                <option value="null">Cochera</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-2 col-xl-2 espaciadoControlesBtn">
            <button type="button" class="btn btn-danger btnBuscar" (click)="buscar()">Buscar</button>
        </div>
    </div>
</div>
<div class="container-fluid" #target>
    <div class="row">
        <div class="col-12 text-center">
            <h1>{{titulo}}</h1>
        </div>
        <p id="TextSuperior" class="text-center" *ngIf="_status!='c'">
            <img src="../../../assets/imgs/1484.gif">
        </p>
        <h4 class="text-center" *ngIf="registros && registros.length==0">Sin resultado de busqueda</h4>
    </div>
    <div class="row">
        <div class="col mostrarDesk"></div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contenedorVivienda bordeVivienda"
                    *ngFor="let registro of registros">
                    <div class="espaciadoInternoVivienda">
                        <div class="row">
                            <div class="col">
                                <h6>{{registro.zonasRutaInversa}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <div class="contenedorImgBuscador">
                                    <div class="contenedorOfertaHome" *ngIf="registro.precioEvento">
                                        <p class="oferta">
                                            Descuento
                                        </p>
                                    </div>
                                    <img [src]="registro.imagen" class="img-fluid mx-auto d-block imgOferta" *ngIf="registro.imagen" />
                                    <img src="https://mucap.blob.core.windows.net/propiedades/ImgDestacada.png" class="img-fluid mx-auto d-block imgOferta" *ngIf="registro.imagen==null"/>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <div class="fondoAzul" *ngIf="registro.precioEvento==null">
                                    <span class="textoPrecio">{{registro.monedasSimbolo}}{{registro.precio |number |
                                        removeComma}}</span>
                                       
                                </div>
                                <div class="fondoRojo" *ngIf="registro.precioEvento">
                                   
                                        <span class="textoPrecio" >{{registro.monedasSimbolo}}{{registro.precioEvento |number |
                                                removeComma}}</span>
                                </div>
                                <div class="contenedorDescripcion">
                                    <p class="textoDescripcion">
                                        {{registro.direccion}} </p>
                                    <p><a class="lnkDetalle" routerLink="detalle/{{registro.codigo}}">Más detalles</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contenedorCaracteristicas">
                        <div class="row fondoCaracteristicas">
                            <div
                                class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-3 bordeMedidaBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-M2.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">{{registro.area}} m2</span>
                            </div>
                            <div class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-3 bordeDormitoriosBuscador textoCaracteristicas"
                                *ngIf="registro.tipo=='a'&& registro.dormitorios!=null">
                                <img src="../../assets/imgs/icono-Dormitorios.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">{{registro.dormitorios}} dormitorios </span>
                            </div>
                            <div class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeBanosBuscador "
                                *ngIf="registro.tipo=='a'&& registro.banios!=null">
                                <img src="../../assets/imgs/icono-Banos.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">{{registro.banios}} baños</span>
                            </div>
                            <div class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeCocheraBuscador textoCaracteristicas"
                                *ngIf="registro.tipo=='a'&& registro.parqueos!=null">
                                <img src="../../assets/imgs/icono-Cochera.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">Cochera {{registro.parqueos}} vehículos</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col mostrarDesk"></div>
    </div>
    <div class="row">
        <div class="col mostrarDesk"></div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
            <div class="" style="float: right;">
                <ul class="pagination">
                
                    <li class="page-item" *ngFor="let pag of paginas" [ngClass]="{'active': pagina==pag.indice}"><a
                            class="page-link" (click)="cambiarPagina(pag.indice)">{{pag.indice}}</a></li>

                    
                </ul>
            </div>
            <div class="col mostrarDesk"></div>
        </div>
        <div class="col mostrarDesk"></div>
    </div>
</div>
<app-mucap-contactenos></app-mucap-contactenos>
<app-mucap-boletin></app-mucap-boletin>
<app-mucap-footer></app-mucap-footer>
<app-mucap-chat></app-mucap-chat>

