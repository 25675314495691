import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PropiedadesRelacionadasService {
  private _url='https://mucap-propiedades-api.azurewebsites.net/api/';
  constructor(private http:HttpClient) { }
  listarPropiedadesRelacionadas(codigoPropiedades: string | null): Observable<any>{
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigoPropiedades": codigoPropiedades
    })
    return this.http.post<any>(this._url + 'propiedades/relacionadasListarXCodigoPropiedades', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  errorHandler(error:HttpErrorResponse){
    return throwError(error);
  }
}
