<div class="container-fluid">
    <!--<div class="row">
        <div class="col-12 text-center">
            <h2>Conocé a nuestro equipo de líderes</h2>
        </div>
    </div>-->
    <div class="row">
        <div class="col-12">
            <iframe src="https://player.flipsnack.com?hash=RkU5QTg2Q0M1QTgreGpmcm01cGQxag==" width="100%" height="480" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe>
        </div>
       
        


</div>
</div>

