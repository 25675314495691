import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mucap-contactenos',
  templateUrl: './mucap-contactenos.component.html',
  styleUrls: ['./mucap-contactenos.component.css']
})
export class MucapContactenosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
