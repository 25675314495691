import { Component, OnInit  } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-mucap-header',
  templateUrl: './mucap-header.component.html',
  styleUrls: ['./mucap-header.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: false, showIndicators: true } }
 ],
})
export class MucapHeaderComponent implements OnInit {
  modalRef?: BsModalRef;
  noWrapSlides = false;
  showIndicator = true;
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    
  }
 
}
