<div class="container-fluid fondoGris">
    <div class="row">
        <div class="col mostrarDesk"></div>
        <div class="col-col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
            <h4>Buscar propiedades</h4>
       
        </div>
        <div class="col-col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
          
        </div>
        <div class="col-col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
           
        </div>
        <div class="col-2 mostrarDesk"></div>
    </div>
    <div class="row">
        <div class="col mostrarDesk"></div>
        
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.tipo" name="propiedad"
                #propiedad="ngModel" (click)="onChange(filtros.tipo)">
                <option value="null">Propiedad</option>
                <option value="a">Vivienda</option>
                <option value="b">Lote</option>
                <option value="c">Comercial</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.codigoZonasNivel0" name="provincia"
                #provincia="ngModel" (change)="cargarCantones(filtros.codigoZonasNivel0)">
                <option value="null">Provincia</option>
                <option *ngFor="let provincia of provincias" [ngValue]="provincia.codigo">{{provincia.descripcion}}</option>
            </select>
        </div>

        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">

            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.codigoZonasNivel1" name="canton" #canton="ngModel" (click)="onChangeCanton(filtros.codigoZonasNivel1)"  >
                <option value="null">Cantón</option>
                <option *ngFor="let canton of cantones" [ngValue]="canton.codigo">{{canton.descripcion}}</option>

            </select>
        </div>
        <div class="col-2 mostrarDesk"></div>
    </div>
    <div class="row row-eq-height">
        <div class="col-12 col-sm-12  col-lg-1 col-xl-1 mostrarDesk"></div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3">
            <div class="row">
                <div class="col-12">
                    <p class="textoFiltro">Rango de precios</p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 form-group">
                        <div class="input-icon">
                            <input class="form-control ctrlBuscador" mask="separator.3" thousandSeparator="." separatorLimit="999999999" [allowNegativeNumbers]="false" placeholder="Currency"  [(ngModel)]="filtros.precioMinimo">
                          <i style="color:#999999">₡ </i>
                        </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <div class="input-icon">
                        <input class="form-control ctrlBuscador" mask="separator.3" thousandSeparator="." separatorLimit="999999999" [allowNegativeNumbers]="false" placeholder="Max"  [(ngModel)]="filtros.precioMaximo">
                      <i style="color:#999999">₡ </i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3" *ngIf="filtros.tipo=='b' || filtros.tipo=='c'">
            <div class="row">
                <div class="col-12">
                    <p class="textoFiltro">Rango de área m2</p>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <div class="input-icon input-icon-right">
                        <input class="form-control ctrlBuscador" mask="separator.2" thousandSeparator="." separatorLimit="999999" [allowNegativeNumbers]="false" placeholder="Min"  [(ngModel)]="filtros.areaMinima">
                      <i style="color:#999999">m2 </i>
                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <div class="input-icon input-icon-right ">
                        <input class="form-control ctrlBuscador" mask="separator.2" thousandSeparator="." separatorLimit="999999" [allowNegativeNumbers]="false" placeholder="Max"  [(ngModel)]="filtros.areaMaxima">
                      <i style="color:#999999">m2 </i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControlesAbajo"
            *ngIf="filtros.tipo=='a'">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.dormitorios" name="dormitorios"
                #dormitorios="ngModel">
                <option value="null">Dormitorios</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControlesAbajo"
            *ngIf="filtros.tipo=='a'">
            <select class="form-select ctrlBuscador" [(ngModel)]="filtros.parqueos" name="parqueos" #parqueos="ngModel">
                <option value="null">Cochera</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-2 col-xl-2 espaciadoControlesBtn">
            <button type="button" class="btn btn-danger btnBuscar" (click)="pasarFiltros(filtros)">Buscar</button>
        </div>
    </div>
</div>