import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { ImagenesService } from 'src/app/servicios/imagenes.service';
import { ArchivosService } from 'src/app/servicios/archivos.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BuscadorService } from 'src/app/servicios/buscador.service';
import { SalidaDatosService } from 'src/app/servicios/salida-datos.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-mucap-detalle-propiedad',
  templateUrl: './mucap-detalle-propiedad.component.html',
  styleUrls: ['./mucap-detalle-propiedad.component.css']
})
export class MucapDetallePropiedadComponent implements OnInit {
  noWrapSlides = false;
  showIndicator = true;
  galleryOptions : NgxGalleryOptions [];
  galleryImages : NgxGalleryImage [];
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  propiedad:any;
  imagenes:any;
  prueba:{small:any,medium:any,big:any};
  archivos:any;
  propiedadRelacionada:any;
  datos:any;
  tamanios:any;
  images:any=[]
  codigo:any;
  pantalla:any;
  @Output()enviar: EventEmitter<any>= new EventEmitter<any>()
  constructor(private imagenesSvg:ImagenesService,private route: ActivatedRoute, private archivosSvg:ArchivosService,
     private buscadorSvg:BuscadorService, private salidaSvg:SalidaDatosService,private router: Router  ) {
  }

  ngOnInit(): void {
      this.codigo=this.route.snapshot.params['codigo'];
      this.listar(this.codigo);   
      this.listarImagenes(this.codigo);
      this.listarArchivos(this.codigo); 
      window.scroll(0,0);
      this.galleryOptions = [
        {
          width: '600px',
          height: '600px',
          thumbnailsColumns: 4,
          imageAutoPlay: true,
          imageAutoPlayPauseOnHover: true,
          imageArrows: false,
          arrowPrevIcon: 'fa fa-arrow-left',
          arrowNextIcon: 'fa fa-arrow-right',
          imageAnimation: NgxGalleryAnimation.Slide
        },
        // max-width 1024
        {
          breakpoint: 1024,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20,
          thumbnailsArrows: true,
          fullWidth: true
        },
        // max-width 800
        {
          breakpoint: 800,
          width: '100%',
          height: '500px',
          imagePercent: 80,
          thumbnailsColumns: 4,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20,
          thumbnailsArrows: true,
          fullWidth: true
        },
        // max-width 400
        {
          breakpoint: 400,
          width: '100%',
          height: '100px',
          preview: false,
          thumbnailsColumns: 2,
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20,
          thumbnailsArrows: true,
          fullWidth: true
        },
        // max-width 320
        {
          breakpoint: 320,
          width: '80%',
          height: '100px',
          preview: false,
          imagePercent: 80,
          thumbnailsColumns: 2,
          thumbnailsPercent: 20,
          thumbnailsMargin: 10,
          thumbnailMargin: 10,
          thumbnailsArrows: false,
          fullWidth: true,
          imageArrows: true
        }
      ];
  
      
      console.log(this.galleryImages);
   
  }
  listarImagenes(codigo:string){
    this._status = 'b'
    this.imagenesSvg.listarImagenes(codigo)
     .subscribe(data =>{
       this.imagenes=[];
       this.images=[];
       this.imagenes=data;
       for (let i = 0; i < this.imagenes.length; i++) {
        this.tamanios={small:this.imagenes[i].imagen,medium:this.imagenes[i].imagen,big:this.imagenes[i].imagen}
        this.images.push(this.tamanios);
        
       }
      this.galleryImages=this.images;
       this._status = 'c'
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  listarArchivos(codigo:string){
    this._status = 'b'
    this.archivosSvg.listarArchivos(codigo)
     .subscribe(data =>{
       this.archivos=data;
      
       this._status = 'c'
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  listar(codigo:string){
    this._status = 'b'
    this.buscadorSvg.listarPropiedad(codigo)
     .subscribe(data =>{
       this.propiedad=data;
       this.datos=Object.assign({}, this.propiedad[0]);
       this._status = 'c'
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  scrollTop(event: any) {
    window.scroll(0,0);
  }
  enviarDatos(){
    if(this.route.snapshot.params['codigo']==undefined){
    this.enviar.emit('activo');
    this.pantalla='desabilitado'
    }else{
      this.router.navigate(['/']);
    }
   
  }
}

  

