import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { FiltrosService } from 'src/app/servicios/filtros.service';
import { SalidaDatosService } from 'src/app/servicios/salida-datos.service';
import { DecimalPipe } from '@angular/common';
import { BuscadorService } from 'src/app/servicios/buscador.service';
@Component({
  selector: 'app-mucap-home',
  templateUrl: './mucap-home.component.html',
  styleUrls: ['./mucap-home.component.css']
})
export class MucapHomeComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  registros:any;
  pagina!:number;
  cantidadRegistrosPagina!:number;
  conteo!:number;
  paginas: {indice:number, grupo:number}[]=[];
  grupos!:number;
  grupo!:number;
  cantidadPaginasGrupo!:number;
  filtros = {
  codigoZonasNivel0: null,
  codigoZonasNivel1: null,
  areaMinima: null,
  areaMaxima: null,
  dormitorios: null,
  parqueos: null,
  precioMinimo: null,
  precioMaximo: null,
  tipo: null,
  activo:true,
  criterio:'%%'
 
  }
  titulo:any;
  propiedadesRecientes:boolean=true;
  provincias:any =[{}];
  canton:any;
  cantones:any;
  cantonesXprovincia:any;
  precios:any;
  precioMin:any;
  precioMax:any;
  areaMin:any;
  areaMax:any;
  minValue: number =0
  maxValue: number=0
  pantalla:any;
  constructor(private salidaSvg:SalidaDatosService, private buscadorSvg:BuscadorService,private filtrosSvg:FiltrosService,private decimalPipe: DecimalPipe) {    
  }

  ngOnInit(): void { 
    this.cargarProvincias();
    this.cargarRangos();
    if(this.propiedadesRecientes){
    this.listarUltimas();
    }
   
  }
  listarUltimas(): void {
    this.titulo='PROPIEDADES RECIENTES'
    this.buscadorSvg.listarUltimas()
    .subscribe(data =>{
      console.log(data);
      this.registros=data
      
      this._status = 'c'

    },
      error => {
       this.errorHttp=error;
       alert(error.statusText);
     });
  }
  obtenerRegistros(): void{
    
    this.titulo='RESULTADOS DE BÚSQUEDA';
    this._status = 'b'
    this.buscadorSvg.buscar(this.filtros.codigoZonasNivel0,this.filtros.codigoZonasNivel1,this.filtros.areaMinima,this.filtros.areaMaxima,this.filtros.dormitorios,
      this.filtros.parqueos,this.filtros.precioMinimo,this.filtros.precioMaximo, this.filtros.tipo,this.filtros.activo,this.filtros.criterio, this.pagina, this.cantidadRegistrosPagina)
     .subscribe(data =>{
      this._status = 'c'
       this.registros=data
       this.conteo = 0;
       if (this.registros.length > 0) { this.conteo = parseInt(this.registros[0].conteo) };
       this.calcularPaginas();
      
    
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  //paginacion
 calcularPaginas() {
  var tmp = Math.ceil(this.conteo / this.cantidadRegistrosPagina);
  var tmpArr = new Array();
  tmp = (tmp > 0 ? tmp : 1);
  for (var i = 1, ii = tmp; i <= ii; i++) {
      tmpArr.push({
          indice: i,
          grupo: Math.ceil((i / this.cantidadPaginasGrupo)),
          clase: (i == this.pagina ? 'active' : '')
      });
  }
  this.paginas = tmpArr;
  this.grupos = Object.assign({},this.paginas[this.paginas.length - 1].grupo);
};
 buscar() {
  this.pagina = 1;
  this.cantidadRegistrosPagina = 6;
  this.cantidadPaginasGrupo=5;
  this.conteo = 0;
  this.paginas = [{ indice: 1, grupo: 1 }];
  this.grupos = 1;
  this.cambiarPagina(1);
}
cambiarPagina(pagina:number){
  this.pagina = pagina;
  this.grupo = Math.ceil((this.pagina / this.cantidadPaginasGrupo));
 this.obtenerRegistros(); 
 window.scrollTo( 0, 900 );
}

retroceder() {
  var tmp = (this.grupo - 1) * this.cantidadPaginasGrupo;
  this.cambiarPagina(tmp);
};
avanzar() {
  var tmp = (this.grupo * this.cantidadPaginasGrupo) + 1;
  this.cambiarPagina(tmp);
};
scrollToBottom(): void {
  try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  } catch(err) { }                 
}
onBlur(event){
  if(event.target.value !== '')
   event.target.value = parseFloat(event.target.value).toFixed(2)
  }
cargarProvincias(): void {
  this.filtrosSvg.listarZonas(null)
  .subscribe(data =>{

    this.provincias=data.filter(function(nivel){return nivel.nivel == 0})
    this._status = 'c'

  },
    error => {
     this.errorHttp=error;
     alert(error.statusText);
   });
}
cargarCantones(provincia){
  this.filtros.codigoZonasNivel1= null;
  if(provincia=="null"){
    this.filtros.codigoZonasNivel0= null;
    this.filtros.codigoZonasNivel1= null;
    if(this.filtros.tipo=="null"){
      this.filtros.tipo=null;
    }
   }
  this._status = 'b'
  this.filtrosSvg.listarZonas(null)
   .subscribe(data =>{
     this.canton=data;
    this.cantones=this.canton.filter(function(codigoZonas){return codigoZonas.codigoZonas == provincia})
     this._status = 'c'

   },
     error => {
      this.errorHttp=error;
      alert(error.statusText);
    }); 
}
cargarRangos(){
this._status = 'b'
this.filtrosSvg.listarRangos()
 .subscribe(data =>{
   this.precios=data[0];
  this.filtros.precioMinimo=this.precios.precioMinimo;
  this.filtros.precioMaximo=this.precios.precioMaximo;
  this.filtros.areaMinima=0;
  this.filtros.areaMaxima=50000;
   this._status = 'c'
 },
   error => {
    this.errorHttp=error;
    alert(error.statusText);
  }); 
}
onChange(tipo){
if(tipo=='b' || tipo=='c'){
   this.filtros.dormitorios=null;
   this.filtros.parqueos=null;
}
if(tipo=="null"){
 this.filtros.codigoZonasNivel0= null;
 this.filtros.codigoZonasNivel1= null;
 this.filtros.areaMinima= null;
 this.filtros.areaMaxima= null;
 this.filtros.dormitorios= null;
 this.filtros.parqueos= null;
 this.filtros.precioMinimo= null;
 this.filtros.precioMaximo= null;
 this.filtros.tipo=null;
}
}
verDetalle(codigo:string){
  this.salidaSvg.disparador.emit(codigo);

}
onChangeCanton(canton){

if(canton=="null"){

 this.filtros.codigoZonasNivel1= null;
 

}

}
recibir(propiedad:any){
  this.pantalla=propiedad;
}
}
