<div class="container-fluid" >
    <header>
        <div class="row">
            <div class="col"></div>
            <div class="col-11 col-sm-12 col-md-5 col-lg-4 col-xl-4"><img
                    src="../../assets/imgs/LogoMucap-VentasBienes.svg" class="logo" />
            </div>
            <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-1"><a href="" class="menuLnk">Inicio</a></div>
            <div class="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-1">
                <button class="menuLnk btnMdlContacto" (click)="lgModal.show()">Contacto</button></div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-1">
                    <button class="menuLnk btnMdlContacto" (click)="lgModalBoletin.show()">Boletín</button></div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 text-center"><a type="button"
                    class="btn btn-danger btnDescarga" href="../../../assets/docs/Formulario_para_Oferta_de_Compra_Venta_de_Bienes.pdf" target="_blank">Hacé tu oferta aquí</a>
                </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1">
                <a href="https://www.facebook.com/MUCAPVentadeBienes" class="redesFacebbokLnk1" target="_blank">
                    
                    <img src="../../assets/imgs/Icono-Facebook.svg" class="imgFacebook" />
                </a>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1">
                <a href="https://www.instagram.com/mucap_ventadebienes/?hl=es" class="redesInstagramLnk1" target="_blank">
                    <img src="../../assets/imgs/Icono-Instagram.svg" class="imgInstagram"/>
                </a>
            </div>
        </div>
    </header>
    <div class="row ">
        <div class="col-12 sinMargen">
            <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator">
                <slide>
                    <img src="../../assets/imgs/Banner_Venta_de_Bienes_Mesa_de_trabajo_1.jpg" class="img-fluid imgCarrusel" />
                </slide>
                <slide>
                    <img src="../../assets/imgs/BannerPrincipal2.jpg" class="img-fluid imgCarrusel" />
                </slide>
                <slide>
                    <img src="../../assets/imgs/BannerPrincipal3.jpg" class="img-fluid imgCarrusel" />
                </slide>
                
            </carousel>
        </div>
    </div>
</div>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog tamanoModal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close close pull-right" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-mucap-contactos-mdl></app-mucap-contactos-mdl>
      </div>
    </div>
  </div>
</div>

<div bsModal #lgModalBoletin="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog tamanoModal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close close pull-right" (click)="lgModalBoletin.hide()" aria-label="Close">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-mucap-boletin-externo-mdl></app-mucap-boletin-externo-mdl>
      </div>
    </div>
  </div>
</div>
