import { Component, OnInit, Input } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { PropiedadesRelacionadasService } from 'src/app/servicios/propiedades-relacionadas.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-mucap-propiedades-relacionadas',
  templateUrl: './mucap-propiedades-relacionadas.component.html',
  styleUrls: ['./mucap-propiedades-relacionadas.component.css']
})
export class MucapPropiedadesRelacionadasComponent implements OnInit {
  propiedadRelacionada:any;
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  private _codigo:any;
  @Input() set codigo(value: string){
    this._codigo=value;
  }
  constructor(private relacionasSvg:PropiedadesRelacionadasService,private route:ActivatedRoute,private router: Router) { 
  
  }

  ngOnInit(): void {
    this.listarPropiedadeRelacionadas();
  }
  listarPropiedadeRelacionadas(){
    this._status = 'b'
    this.relacionasSvg.listarPropiedadesRelacionadas(this.route.snapshot.params['codigo'])
     .subscribe(data =>{
       this.propiedadRelacionada=data;
       this._status = 'c'
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  
}
