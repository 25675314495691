import { Component, OnInit } from '@angular/core';

import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BoletinService } from 'src/app/servicios/boletin.service';
@Component({
  selector: 'app-mucap-boletin',
  templateUrl: './mucap-boletin.component.html',
  styleUrls: ['./mucap-boletin.component.css']
})
export class MucapBoletinComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  constructor(private boletinSvg:BoletinService) { }
correo={
  nombre:'',
  telefono:'',
  correoElectronico:'',
  mensaje:''
}
  ngOnInit(): void {
  }
  enviarCorreo(): void{
    this._status = 'b'
    this.boletinSvg.envioCorreo(this.correo.nombre, this.correo.telefono, this.correo.correoElectronico, this.correo.mensaje)
     .subscribe(data=> {
      this._status = 'c'
      alert("Envio éxitoso");
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
}
