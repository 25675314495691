import { Component, OnInit  } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: false, showIndicators: true } }
 ],
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  modalRef?: BsModalRef;
  noWrapSlides = false;
  showIndicator = true;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    
  }
 
}
