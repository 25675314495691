import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { FiltrosService } from 'src/app/servicios/filtros.service';
import { SalidaDatosService } from 'src/app/servicios/salida-datos.service';
import { DecimalPipe } from '@angular/common';
@Component({
  selector: 'app-mucap-filtros-busqueda',
  templateUrl: './mucap-filtros-busqueda.component.html',
  styleUrls: ['./mucap-filtros-busqueda.component.css']
})
export class MucapFiltrosBusquedaComponent implements OnInit {
  
  
  filtros = {
    codigoZonasNivel0: null,
    codigoZonasNivel1: null,
    areaMinima: null,
    areaMaxima: null,
    dormitorios: null,
    parqueos: null,
    precioMinimo: null,
    precioMaximo: null,
    tipo: null,
    activo:true,
    criterio:'%%'
    }
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  provincias:any =[{}];
  canton:any;
  cantones:any;
  cantonesXprovincia:any;
  precios:any;
  precioMin:any;
  precioMax:any;
  areaMin:any;
  areaMax:any;
  minValue: number =0
  maxValue: number=0

  constructor(private filtrosSvg:FiltrosService,private salidaSvg:SalidaDatosService,private decimalPipe: DecimalPipe) {   this.cargarRangos();}

  ngOnInit(): void {
    this.cargarProvincias();
  
  }
  onBlur(event){
    if(event.target.value !== '')
     event.target.value = parseFloat(event.target.value).toFixed(2)
    }
  cargarProvincias(): void {
    this.filtrosSvg.listarZonas(null)
    .subscribe(data =>{

      this.provincias=data.filter(function(nivel){return nivel.nivel == 0})
      this._status = 'c'

    },
      error => {
       this.errorHttp=error;
       alert(error.statusText);
     });
  }
  cargarCantones(provincia){
    this.filtros.codigoZonasNivel1= null;
    if(provincia=="null"){
      this.filtros.codigoZonasNivel0= null;
      this.filtros.codigoZonasNivel1= null;
      if(this.filtros.tipo=="null"){
        this.filtros.tipo=null;
      }
     }
    this._status = 'b'
    this.filtrosSvg.listarZonas(null)
     .subscribe(data =>{
       this.canton=data;
      this.cantones=this.canton.filter(function(codigoZonas){return codigoZonas.codigoZonas == provincia})
       this._status = 'c'

     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
}
cargarRangos(){
  this._status = 'b'
  this.filtrosSvg.listarRangos()
   .subscribe(data =>{
     this.precios=data[0];
    this.filtros.precioMinimo=this.precios.precioMinimo;
    this.filtros.precioMaximo=this.precios.precioMaximo;
    this.filtros.areaMinima=0;
    this.filtros.areaMaxima=50000;
     this._status = 'c'
   },
     error => {
      this.errorHttp=error;
      alert(error.statusText);
    }); 
}
onChange(tipo){
  if(tipo=='b' || tipo=='c'){
     this.filtros.dormitorios=null;
     this.filtros.parqueos=null;
  }
  if(tipo=="null"){
   this.filtros.codigoZonasNivel0= null;
   this.filtros.codigoZonasNivel1= null;
   this.filtros.areaMinima= null;
   this.filtros.areaMaxima= null;
   this.filtros.dormitorios= null;
   this.filtros.parqueos= null;
   this.filtros.precioMinimo= null;
   this.filtros.precioMaximo= null;
   this.filtros.tipo=null;
  }
 }
 onChangeCanton(canton){
  
  if(canton=="null"){
 
   this.filtros.codigoZonasNivel1= null;
   
  
  }
 }
pasarFiltros(filtros:any){
  this.salidaSvg.disparador.emit(this.filtros);
 
}
}
