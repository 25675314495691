<div class="container-fluid fondoGris">
    <div class="row">
        <div class="col mostrarDesk"></div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador">
                <option value="">Tipo de Propiedad</option>
                <option value="">Vivienda</option>
                <option value="">Lote</option>
                <option value="">Comercial</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador">
                <option value="">Seleccion la Provincia</option>
                <option value="">Alajuela</option>
                <option value="">Cartago</option>
                <option value="">Guanacaste</option>
                <option value="">Heredia</option>
                <option value="">Limón</option>
                <option value="">Puntarenas</option>
                <option value="">San José</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador">
                <option value="">Seleccione el Cantón</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select>
        </div>
        <div class="col-2 mostrarDesk"></div>
    </div>
    <div class="row row-eq-height">
        <div class="col mostrarDesk"></div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <div class="row">
                <div class="col-5">
                    <input class="form-control ctrlBuscador" type="text" placeholder="Min">
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <input class="form-control ctrlBuscador" type="text" placeholder="Max">
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador">
                <option value="">Dormitorios</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3 col-xl-3 espaciadoControles">
            <select class="form-select ctrlBuscador">
                <option value="">Cochera</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-2 col-xl-2">
            <button type="button" class="btn btn-danger btnBuscar">Buscar</button>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center">
            <h1>Últimas propiedades agregadas</h1>
        </div>
    </div>
    <div class="row">
        <div class="col mostrarDesk"></div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contenedorVivienda bordeVivienda">
                    <div class="espaciadoInternoVivienda">
                        <div class="row">
                            <div class="col">
                                <h6>Vivienda de una planta</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <div class="contenedorImgBuscador">
                                    <img src="../../assets/imgs/Img-Propiedades2-412x276.png" class="img-fluid mx-auto d-block imgOferta" />
                                    <div class="contenedorOfertaHome">
                                        <p class="oferta">
                                            Oferta
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <div class="fondoAzul">
                                    <span class="textoPrecio">278475297592</span>
                                </div>
                                <div class="contenedorDescripcion">
                                    <p class="textoDescripcion">Text Text Text Text Text Text Text Text Text Text Text
                                        Text
                                        Text Text Text Text Text Text Text Text
                                        Text Text </p>
                                    <p><a class="lnkDetalle">Más detalles</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contenedorCaracteristicas">
                        <div class="row fondoCaracteristicas">
                            <div
                                class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeMedidaBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-M2.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">152 m2</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeDormitoriosBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Dormitorios.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 dormitorios</span>
                            </div>
                            <div class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeBanosBuscador ">
                                <img src="../../assets/imgs/icono-Banos.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 baños</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeCocheraBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Cochera.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">Cochera 1 vehículo</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contenedorVivienda bordeVivienda">
                    <div class="espaciadoInternoVivienda">
                        <div class="row">
                            <div class="col">
                                <h6>Vivienda de una planta</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <img src="../../assets/imgs/Img-Propiedades1-412x276.png" class="img-fluid mx-auto d-block" />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <div class="fondoAzul">
                                    <span class="textoPrecio">278475297592</span>
                                </div>
                                <div class="contenedorDescripcion">
                                    <p class="textoDescripcion">Text Text Text Text Text Text Text Text Text Text Text
                                        Text
                                        Text Text Text Text Text Text Text Text
                                        Text Text </p>
                                    <p><a class="lnkDetalle">Más detalles</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contenedorCaracteristicas">
                        <div class="row fondoCaracteristicas">
                            <div
                                class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeMedidaBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-M2.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">152 m2</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeDormitoriosBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Dormitorios.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 dormitorios</span>
                            </div>
                            <div class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeBanosBuscador ">
                                <img src="../../assets/imgs/icono-Banos.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 baños</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeCocheraBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Cochera.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">Cochera 1 vehículo</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contenedorVivienda bordeVivienda">
                    <div class="espaciadoInternoVivienda">
                        <div class="row">
                            <div class="col">
                                <h6>Vivienda de una planta</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <img src="../../assets/imgs/Img-Propiedades2-412x276.png" class="img-fluid mx-auto d-block" />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <div class="fondoAzul">
                                    <span class="textoPrecio">278475297592</span>
                                </div>
                                <div class="contenedorDescripcion">
                                    <p class="textoDescripcion">Text Text Text Text Text Text Text Text Text Text Text
                                        Text
                                        Text Text Text Text Text Text Text Text
                                        Text Text </p>
                                    <p><a class="lnkDetalle">Más detalles</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contenedorCaracteristicas">
                        <div class="row fondoCaracteristicas">
                            <div
                                class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeMedidaBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-M2.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">152 m2</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeDormitoriosBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Dormitorios.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 dormitorios</span>
                            </div>
                            <div class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeBanosBuscador ">
                                <img src="../../assets/imgs/icono-Banos.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 baños</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeCocheraBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Cochera.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">Cochera 1 vehículo</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contenedorVivienda bordeVivienda">
                    <div class="espaciadoInternoVivienda">
                        <div class="row">
                            <div class="col">
                                <h6>Vivienda de una planta</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <img src="../../assets/imgs/Img-Propiedades2-412x276.png" class="img-fluid mx-auto d-block" />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sinMargen">
                                <div class="fondoAzul">
                                    <span class="textoPrecio">278475297592</span>
                                </div>
                                <div class="contenedorDescripcion">
                                    <p class="textoDescripcion">Text Text Text Text Text Text Text Text Text Text Text
                                        Text
                                        Text Text Text Text Text Text Text Text
                                        Text Text </p>
                                    <p><a class="lnkDetalle">Más detalles</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contenedorCaracteristicas">
                        <div class="row fondoCaracteristicas">
                            <div
                                class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeMedidaBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-M2.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">152 m2</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeDormitoriosBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Dormitorios.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 dormitorios</span>
                            </div>
                            <div class="col-5 col-sm-5 col-md-2 col-lg-5 col-xl-2 bordeBanosBuscador ">
                                <img src="../../assets/imgs/icono-Banos.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">2 baños</span>
                            </div>
                            <div
                                class="col-7 col-sm-7 col-md-4 col-lg-7 col-xl-4 bordeCocheraBuscador textoCaracteristicas">
                                <img src="../../assets/imgs/icono-Cochera.svg" class="imgCaracteristicas" />
                                <span class="textoCaracteristicas">Cochera 1 vehículo</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col mostrarDesk"></div>
    </div>
</div>