<div class="container-fluid">
    <footer>
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
                <hr />
                <p class="textoFooter">Derechos Reservados © 2021 Mucap - Venta de Bienes</p>
                <p class="textoFooter">Reservados todos los derechos</p>
            </div>
            <div class="col-1">
            </div>
        </div>
    </footer>
</div>