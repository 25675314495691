<div class="container-fluid">
    <div class="contenedorDetalle">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                    <div class="col-12 espaciadoAbajo">
                        <a href="" class="lnkVolver">
                            << Volver a los resultados</a>
                                <a href="#" class="lnkBredcrumbs">Viviendas</a>
                                <a href="#" class="lnkBredcrumbs"> > San José </a>
                                
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                <div class="row espaciadoArriba">
                    <div class="col-12" style="min-height: 800px;">
                        <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery galeria">
                        </ngx-gallery>

                    </div>
                </div>
                <div class="contenedorTextoPropiedad">
                    <div class="row fondoTituloPropiedad sinPadding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
                            <p class="tituloPropiedad">Vivienda de una planta</p>
                        </div>
                        <div class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-3 sinPadding fondoAzulTituloVivienda">
                            <div class="fondoPrecio">₡57,176,050.00</div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-7 col-lg-5 col-xl-4 fondoAzulPrecio sinPadding">
                            <div class="fondoOferta">Oferta ₡57,176,050.00</div>
                        </div>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
                        <p class="textoCodigosPropiedad">Código: 90006172</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <p class="textoCodigosPropiedad">N° folio real: 3-126308 F-000</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <p class="textoCodigosPropiedad">N° plano catastro: C-1752299-2014</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
                </div>
                <div class="row fondoTituloPropiedad">
                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 ">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-M2.svg"
                                class="imgCaracteristicas" />
                            152 m2</p>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 textoCaracteristicasDetalle">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-M2.svg"
                                class="imgCaracteristicas" />
                            127 m2</p>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 textoCaracteristicasDetalle">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-Dormitorios.svg"
                                class="imgCaracteristicas" />
                            2 dormitorios</p>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 ">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-Banos.svg"
                                class="imgCaracteristicas" />
                            2 baños</p>
                    </div>
                    <div class="col-11 col-sm-12 col-md-8 col-lg-3 col-xl-3 textoCaracteristicasDetalle">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-Cochera.svg"
                                class="imgCaracteristicas" />
                            Cochera 1 vehículo</p>
                    </div>
                    <div class="col"></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="textoDetalle">Hermosa vivienda de 1 planta, cuenta con sala, comedor, cocina, 3
                            dormitorios, 1 baño completo, área de pilas y tendido y cochera para 1 vehículo. Soporta
                            gravamenes de Servidumbre Trasladada. Financiamiento del 90% del precio de venta.</p>
                    </div>
                </div>
                <div class="row fondoTituloPropiedad">
                    <div class="col-8 col-sm-8 col-md-8 col-lg-10 col-xl-10">
                        <h4>Ubicación</h4>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1">
                        <img src="../../assets/imgs/Icono-Googlemaps.svg" class="imgGoogleMaps" />
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1">
                        <img src="../../assets/imgs/Icono-Waze.svg" class="imgWaze" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="textoDetalle">Aguacaliente (San Francisco), Cartago, Cartago
                            costado norte del complejo deportivo Sport Center; dentro de la Urbanización Hacienda de
                            Oro, casa #21-F.</p>
                    </div>
                </div>
                <div class="row fondoTituloPropiedad">
                    <div class="col-12">
                        <h4>Observaciones</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="textoDetalle">Mucap se reserva el derecho de vender el bien a la oferta que se haga
                            por un precio mayor al valor de referencia o conforme al análisis de la Comisión de Bienes
                            Adjudicados que sea más favorable para la entidad. La información de los inmuebles así como
                            su disponibilidad se actualiza de manera periódica, no de manera inmediata, razón por la
                            cual, Mucap se exime de toda responsabilidad en el caso de que un inmueble ya no esté
                            disponible para la venta o la información acá consignada no sea la actual, en ese sentido,
                            el oferente deberá consultar previamente si el inmueble de su interés ya ha sido ofertado o
                            vendido a otra persona.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h5>Archivos relacionados</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="button" class="btn btnAzul">Ficha Técnica</button>
                        <button type="button" class="btn btnInforme">Informe Registral</button>
                        <button type="button" class="btn btnAzul">Plano Catastro</button>
                    </div>
                </div>
                <div class="row fondoTituloPropiedad">
                    <div class="col-12">
                        <h4>Video</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col contenedorVideo">
                        <iframe class="responsive-iframe" width="560" height="315"
                            src="https://www.youtube.com/embed/jhEkYOfgINw" title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                <div class="row">
                    <div class="col-12">
                        <iframe class="contenedorCotizador"
                            src="https://credicase.mucap.fi.cr:7005/ORC.CRE.UIL/Paginas/CRE_P_CotizadorWeb.aspx"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <button type="button" class="btn btn-danger btnOfertar">Descargar documento para ofertar por
                            esta propiedad</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sinPaddingDer">
                        <div class="contenedorPropiedadesSimilares ">
                            <p class="tituloPropiedadesSimilares">Propiedades similares</p>
                            <hr />
                            <div class="contenedorDetallePropiedadesSimilares ">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="contenedorImgPropiedadesSimilares">
                                            <img src="../../assets/imgs/Img-Propiedades2-412x276.png" class="img-fluid" />
                                            <div class="contenedorOferta">
                                                <p class="oferta">
                                                    Oferta
                                                </p>
                                            </div>
                                            <p class="textoPrecioPropiedadesSimilares">₡57,176,050.00</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p class="tituloPropiedadPropiedadesSimilares">Terreno en Capellades</p>
                                        <p class="textoPropiedadPropiedadesSimilares">La Ribera, Belén, Heredia
                                            Del cementerio de la Ribera, 130 metros al noroeste y 60 metros al sur.</p>
                                        <a class="lnkPropiedasSimilares" href="">Más detalles ></a>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="contenedorDetallePropiedadesSimilares ">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="contenedorImgPropiedadesSimilares">
                                            <img src="../../assets/imgs/Img-Propiedades1-412x276.png"
                                                class="img-fluid" />
                                            <p class="textoPrecioPropiedadesSimilares">₡57,176,050.00</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p class="tituloPropiedadPropiedadesSimilares">Terreno en Capellades</p>
                                        <p class="textoPropiedadPropiedadesSimilares">La Ribera, Belén, Heredia
                                            Del cementerio de la Ribera, 130 metros al noroeste y 60 metros al sur.</p>
                                        <a class="lnkPropiedasSimilares" href="">Más detalles ></a>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>