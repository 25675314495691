import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MockupComponent } from './mockup/mockup.component';
import { DetalleComponent } from './detalle/detalle.component';
import { HeaderComponent } from './header/header.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { FooterComponent } from './footer/footer.component';
import { BoletinComponent } from './boletin/boletin.component';
import { ContactosComponent } from './contactos/contactos.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ContactosMdlComponent } from './contactos-mdl/contactos-mdl.component';
import { MucapHeaderComponent } from './componentes/mucap-header/mucap-header.component';
import { MucapFooterComponent } from './componentes/mucap-footer/mucap-footer.component';
import { MucapDetallePropiedadComponent } from './componentes/mucap-detalle-propiedad/mucap-detalle-propiedad.component';
import { MucapContactosMdlComponent } from './componentes/mucap-contactos-mdl/mucap-contactos-mdl.component';
import { MucapContactenosComponent } from './componentes/mucap-contactenos/mucap-contactenos.component';
import { MucapFiltrosBusquedaComponent } from './componentes/mucap-filtros-busqueda/mucap-filtros-busqueda.component';
import { MucapBuscadorComponent } from './componentes/mucap-buscador/mucap-buscador.component';
import { MucapBoletinComponent } from './componentes/mucap-boletin/mucap-boletin.component';
import { MucapHomeComponent } from './componentes/mucap-home/mucap-home.component';
import { MucapInformacionPropiedadComponent } from './componentes/mucap-informacion-propiedad/mucap-informacion-propiedad.component';
import { OrderByPipe } from './order-by.pipe';
import { MucapPropiedadesRelacionadasComponent } from './componentes/mucap-propiedades-relacionadas/mucap-propiedades-relacionadas.component';
import { DecimalPipe } from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import { OnlyPositiveDirective } from 'src/only-positive.directive';
import { MucapChatComponent } from './componentes/mucap-chat/mucap-chat.component';
import { MucapBoletinExternoMdlComponent } from './componentes/mucap-boletin-externo-mdl/mucap-boletin-externo-mdl.component';
@NgModule({
  declarations: [
    AppComponent,
    MockupComponent,
    DetalleComponent,
    HeaderComponent,
    FooterComponent,
    BoletinComponent,
    ContactosComponent,
    BuscadorComponent,
    ContactosMdlComponent,
    MucapHeaderComponent,
    MucapFooterComponent,
    MucapDetallePropiedadComponent,
    MucapContactosMdlComponent,
    MucapContactenosComponent,
    MucapFiltrosBusquedaComponent,
    MucapBuscadorComponent,
    MucapBoletinComponent,
    MucapHomeComponent,
    MucapInformacionPropiedadComponent,
    OrderByPipe,
    MucapPropiedadesRelacionadasComponent,
    OnlyPositiveDirective,
    MucapChatComponent,
    MucapBoletinExternoMdlComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    NgxGalleryModule,
    NgxSliderModule,
    NgxMaskModule.forRoot()
  ],
  providers: [DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
