import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Provincia } from '../interfaces/provincia';
@Injectable({
  providedIn: 'root'
})
export class FiltrosService {
  private _url='https://mucap-propiedades-api.azurewebsites.net/api/';

  constructor( private http:HttpClient) { }
  listarZonas(codigo: string | null): Observable<any>{
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
    })
    return this.http.post<any>(this._url + 'zonas/listar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  listarRangos(): Observable<any>{
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
   
    })
    return this.http.post<any>(this._url + 'propiedades/rangosListar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  errorHandler(error:HttpErrorResponse){
    return throwError(error);
  }

 
}
