<div class="container-fluid" >
    <div class="contenedorDetalle">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                    <div class="col-12 espaciadoAbajo">
                        <a href="" class="lnkVolver">
                            << Volver</a>
                                <a href="" class="lnkBredcrumbs">{{datos?.tiposDescripcion}}</a>
                                <a href="" class="lnkBredcrumbs"> > {{datos?.zonasRutaInversa}} </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" (activate)="scrollTop($event)">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                <div class="row espaciadoArriba">
                    <p id="TextSuperior" class="text-center" *ngIf="_status!='c'" >
                        <img src="../../../assets/imgs/1484.gif">
                      </p>
                      <div class="col-12 altoGaleriaInterna" *ngIf="galleryImages && galleryImages.length==0">
                        <img src="https://mucap.blob.core.windows.net/propiedades/ImgGaleria.png" class="img-fluid mx-auto d-block imgOferta" />
                      </div>
                    <div class="col-12 altoGaleriaInterna" *ngIf="galleryImages && galleryImages.length>0">
                        <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery galeria">
                        </ngx-gallery>

                    </div>
                </div>
                <div class="contenedorTextoPropiedad">
                    <div class="row fondoTituloPropiedad sinPadding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
                            <p class="tituloPropiedad">{{datos?.zonasRutaInversa}}</p>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 sinPadding fondoAzulTituloVivienda">
                            <div class="fondoPrecio"*ngIf="datos?.precioEvento==null">Precio actual: {{datos?.monedasSimbolo}}{{datos?.precio  |number | removeComma}}</div>
                            <div class="fondoPrecio" *ngIf="datos?.precioEvento">Precio anterior: {{datos?.monedasSimbolo}}{{datos?.precio  |number | removeComma}}</div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 fondoAzulPrecio sinPadding">
                            <div class="fondoOferta" *ngIf="datos?.precioEvento">Precio descuento: {{datos?.monedasSimbolo}}{{datos?.precioEvento |number | removeComma}}</div>
                        </div>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-3">
                        <p class="textoCodigosPropiedad">Código: {{datos?.identificador}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <p class="textoCodigosPropiedad">N° folio real: {{datos?.folio}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <p class="textoCodigosPropiedad">N° plano catastro: {{datos?.plano}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
                </div>
                <div class="row fondoTituloPropiedad">
                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 ">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-M2.svg"
                                class="imgCaracteristicas" />
                            {{datos?.area}} m2</p>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 textoCaracteristicasDetalle" *ngIf="datos?.tipo=='a'&& datos?.construccion!=null">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-AreaConstru.svg"
                                class="imgCaracteristicas" />
                            {{datos?.construccion}} m2</p>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 textoCaracteristicasDetalle"*ngIf="datos?.tipo!='b'&& datos?.dormitorios!=null">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-Dormitorios.svg"
                                class="imgCaracteristicas" />
                            {{datos?.dormitorios}} dormitorios</p>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 " *ngIf="datos?.tipo=='a' && datos?.banios!=null">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-Banos.svg"
                                class="imgCaracteristicas" />
                            {{datos?.banios}} baños</p>
                    </div>
                    <div class="col-11 col-sm-12 col-md-8 col-lg-3 col-xl-3 textoCaracteristicasDetalle" *ngIf="datos?.tipo=='a' && datos?.parqueos!=null">
                        <p class="textoCaracteristicasDetalle"><img src="../../assets/imgs/icono-Cochera.svg"
                                class="imgCaracteristicas" />
                            Cochera {{datos?.parqueos}} vehículo</p>
                    </div>
                    <div class="col"></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="textoDetalle">{{datos?.descripcion}}</p>
                    </div>
                </div>
                <div class="row fondoTituloPropiedad">
                    <div class="col-8 col-sm-8 col-md-8 col-lg-10 col-xl-10">
                        <h4>Ubicación</h4>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1">
                      <a href="{{datos?.enlaceGoogleMaps}}" target="_blank">  <img src="../../assets/imgs/Icono-Googlemaps.svg" class="imgGoogleMaps" /></a>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1">
                        <a href="https://waze.com/ul?ll={{datos?.latitud}},{{datos?.longitud}}&z=10" target="_blank"><img src="../../assets/imgs/Icono-Waze.svg" class="imgWaze" /></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="textoDetalle">{{datos?.zonasRutaInversa}} {{datos?.direccion}}</p>
                    </div>
                </div>
                <div class="row fondoTituloPropiedad">
                    <div class="col-12">
                        <h4>Observaciones</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="textoDetalle">{{datos?.observaciones}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h5>Archivos relacionados</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a type="button" class="btn btnAzul" *ngFor="let archivo of archivos" href="https://mucap-propiedades-admin.azurewebsites.net/_account/docs/{{archivo.ruta}}" target="_blank">{{archivo.nombre}}</a>
                        
                    
                    </div>
                </div>
                <div class="row fondoTituloPropiedad" *ngIf="datos.video">
                    <div class="col-12">
                        <h4>Video</h4>
                    </div>
                </div>
                <div class="row"*ngIf="datos.video" >
                    <div class="col contenedorVideo">
                        <iframe class="responsive-iframe" width="560" height="315"
                            src="{{datos?.video}}" title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                <div class="row">
                    <div class="col-12">
                        <a type="button" href="https://credicase.mucap.fi.cr:7005/ORC.CRE.UIL/Paginas/CRE_P_CotizadorWeb.aspx" target="_blank" class="btn btnCalcularCuota">Calcule su cuota aquí</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <a type="button" class="btn btn-danger btnOfertar" href="../../../assets/docs/Formulario_para_Oferta_de_Compra_Venta_de_Bienes.pdf" target="_blank">Descargar documento para ofertar por
                            esta propiedad</a>
                    </div>
                </div>
                <div class="row">
                    <app-mucap-propiedades-relacionadas></app-mucap-propiedades-relacionadas>
                </div>
            </div>
        </div>
    </div>
</div>