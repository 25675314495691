<div class="container">
    <div class="row">
        <div class="col" style="background-color: blue;"></div>
        <div class="col-5"><img src="../../assets/imgs/colaboradores-05.jpg"/></div>
        <div class="col"><a href="">Inicio</a></div>
        <div class="col"><a href="">Contáctenos</a></div>
        <div class="col-2"><button type="button" class="btn btn-danger">Descarga Documento</button></div>
        <div class="col"><a href=""><img src="../../assets/imgs/redes-01.png"/></a></div>
        <div class="col"><a href=""><img src="../../assets/imgs/redes-02.png"/></a></div>
    </div>
    <div class="row">
        <div class="col-12"><img src="../../assets/imgs/banner1.jpeg" class="img-fluid"/></div>
    </div>
    <div class="row">
        <div class="col" style="background-color: blue;"></div>
        <div class="col-3">
            <select class="form-select">
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select>
        </div>
        <div class="col-3">
            <select class="form-select">
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select></div>
        <div class="col-3">
            <select class="form-select">
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select></div>
        <div class="col"></div>
        <div class="col" style="background-color: green;"></div>
    </div>
    <div class="row">
        <div class="col" style="background-color: blue;"></div>
        <div class="col">
            <input class="form-control" type="text">
        </div>
        <div class="col">
        </div>
        <div class="col">
            <input class="form-control" type="text">
        </div>
        <div class="col-3">
            <select class="form-select">
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select></div>
        <div class="col-3">
            <select class="form-select">
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
                <option value="">Opcion 1</option>
            </select></div>
        <div class="col"><button type="button" class="btn btn-danger">Buscar</button></div>
        <div class="col" style="background-color: green;"></div>
    </div>
    <div class="row">
        <div class="col-12">Últimas propiedades agregadas</div>
    </div>
    <div class="row">
        <div class="col" style="background-color: blue;">Colum</div>
        <div class="col-5" style="border: 1px solid gray;">
            <div class="row">
                <div class="col" style="background-color: blue;">Vivienda de una planta</div>
            </div>
            <div class="row">
                <div class="col-6"><img src="../../assets/imgs/colaboradores-05.jpg" /></div>
                <div class="col-6" style="background-color: white;">
                    <p>278475297592</p>
                    <p>Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text
                        Text Text </p>
                    <p><a>Más detalles</a></p>
                </div>
            </div>
            <div class="row">
                <div class="col-2" style="background-color: blue;">152 m2</div>
                <div class="col-3" style="background-color: black;">2 dormitorios</div>
                <div class="col-2" style="background-color: white;">2 baños</div>
                <div class="col-5" style="background-color: green;">Cochera 1 vehículo/div>
                </div>
            </div>
        </div>
        <div class="col-5" style="border: 1px solid gray;">
            <div class="row">
                <div class="col" style="background-color: blue;">Vivienda de una planta</div>
            </div>
            <div class="row">
                <div class="col-6"><img src="../../assets/imgs/colaboradores-05.jpg" /></div>
                <div class="col-6" style="background-color: white;">
                    <p>278475297592</p>
                    <p>Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text
                        Text Text </p>
                    <p><a>Más detalles</a></p>
                </div>
            </div>
            <div class="row">
                <div class="col-2" style="background-color: blue;">152 m2</div>
                <div class="col-3" style="background-color: black;">2 dormitorios</div>
                <div class="col-2" style="background-color: white;">2 baños</div>
                <div class="col-5" style="background-color: green;">Cochera 1 vehículo/div>
                </div>
            </div>
        </div>
        <div class="col" style="background-color: blue;">Colum</div>
    </div>
    <div class="row">
        <div class="col-12"><p>Contactos</p><p>Conocé a nuestro equipo de líderes</p></div>
    </div>
    <div class="row">
        <div class="col" style="background-color: blue;"></div>
        <div class="col-2">
            <div class="row">
                <div class="col"><img src="../../assets/imgs/colaboradores-01.jpg" /></div>
            </div>
            <div class="row">
                <div class="col"><p>Ronald Sánchez Aguilar</p></div>
            </div>
            <div class="row">
                <div class="col">
                    <p><img src="../../assets/imgs/contacto-01.png" /> 23424222</p>
                    <p><img src="../../assets/imgs/contacto-02.png" /> a@a.com</p>
                    <p><img src="../../assets/imgs/contacto-03.png" /> 23424222</p>
                </div>
            </div>
        </div>
        <div class="col-2" style="background-color: white;">
            <div class="row">
                <div class="col"><img src="../../assets/imgs/colaboradores-01.jpg" /></div>
            </div>
            <div class="row">
                <div class="col"><p>Ronald Sánchez Aguilar</p></div>
            </div>
            <div class="row">
                <div class="col">
                    <p><img src="../../assets/imgs/contacto-01.png" /> 23424222</p>
                    <p><img src="../../assets/imgs/contacto-02.png" /> a@a.com</p>
                    <p><img src="../../assets/imgs/contacto-03.png" /> 23424222</p>
                </div>
            </div>
        </div>
        <div class="col-2" style="background-color: red;">
            <div class="row">
                <div class="col"><img src="../../assets/imgs/colaboradores-01.jpg" /></div>
            </div>
            <div class="row">
                <div class="col"><p>Ronald Sánchez Aguilar</p></div>
            </div>
            <div class="row">
                <div class="col">
                    <p><img src="../../assets/imgs/contacto-01.png" /> 23424222</p>
                    <p><img src="../../assets/imgs/contacto-02.png" /> a@a.com</p>
                    <p><img src="../../assets/imgs/contacto-03.png" /> 23424222</p>
                </div>
            </div>
        </div>
        <div class="col-2" style="background-color: green;">
            <div class="row">
                <div class="col"><img src="../../assets/imgs/colaboradores-01.jpg" /></div>
            </div>
            <div class="row">
                <div class="col"><p>Ronald Sánchez Aguilar</p></div>
            </div>
            <div class="row">
                <div class="col">
                    <p><img src="../../assets/imgs/contacto-01.png" /> 23424222</p>
                    <p><img src="../../assets/imgs/contacto-02.png" /> a@a.com</p>
                    <p><img src="../../assets/imgs/contacto-03.png" /> 23424222</p>
                </div>
            </div>
        </div>
        <div class="col-2" style="background-color: black;">
            <div class="row">
                <div class="col"><img src="../../assets/imgs/colaboradores-01.jpg" /></div>
            </div>
            <div class="row">
                <div class="col"><p>Ronald Sánchez Aguilar</p></div>
            </div>
            <div class="row">
                <div class="col">
                    <p><img src="../../assets/imgs/contacto-01.png" /> 23424222</p>
                    <p><img src="../../assets/imgs/contacto-02.png" /> a@a.com</p>
                    <p><img src="../../assets/imgs/contacto-03.png" /> 23424222</p>
                </div>
            </div>
        </div>
        <div class="col" style="background-color: green;">Colum</div>
    </div>
    <div class="row">
        <div class="col" style="background-color: blue;">Colum</div>
        <div class="col-7" style="background-color: black;">
            <p>¡Suscribete a nuestro boletín!</p>
            <p>Enterate de las nuevas propiedades a la venta, promociones, descuentos y más beneficios.</p>
        </div>
        <div class="col-2" > <input class="form-control" type="text">
            <input class="form-control" type="text">
            <input class="form-control" type="text"></div>
        <div class="col"><button type="button" class="btn btn-danger">Buscar</button></div>
        <div class="col" style="background-color: green;">Colum</div>
    </div>
    <div class="row">
        <div class="col-12" style="background-color: gray;">
            <p>Derechos Reservados © 2021 Mucap - Venta de Bienes</p>
            <p>Reservados todos los derechos</p>
        </div>
    </div>
</div>