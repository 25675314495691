import {Component, Inject, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-mucap-chat',
  templateUrl: './mucap-chat.component.html',
  styleUrls: ['./mucap-chat.component.css']
})
export class MucapChatComponent implements OnInit {

   @Input() id: string;
  script = this._renderer.createElement('script');

  constructor(private _renderer: Renderer2, @Inject(DOCUMENT) private _document: any, ) {}

  ngOnInit() {
    this.script.text = `var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/58b5c93bab48ef44eccd3ad9/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();`;
    this._renderer.appendChild(this._document.body, this.script);
  }
}